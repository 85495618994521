<script setup lang="ts">
import type { CmsElementZebraCmsFaq } from "~/composables/useCustom";
import 'vue3-carousel/dist/carousel.css'
import {getProductsById} from "~/server/apiBuilder";
import SwListingProductPrice from "~/components/product/SwListingProductPrice.vue";
import {buildUrlPrefix, getProductRoute} from "@shopware/helpers";
import {RouterLink} from "vue-router";
import {useUrlResolver} from "#imports";
import SharedWishlist from "~/components/shared/SharedWishlist.vue";
import {useCachedMediaById} from "~/components/server/useCachedMediaById";

const props = defineProps<{
  content: CmsElementZebraCmsFaq;
}>();

const { getUrlPrefix } = useUrlResolver();

const { products, productMediaHotspots, media } = props.content?.config
const searchResults = ref([]);
const MainImage = ref();
const loading = ref(true);

onMounted(async() => {
  searchResults.value = await getProductsById({
    "ids": products.value
  });

  if (media.value) {
    MainImage.value = await useCachedMediaById(media.value);
  }
  loading.value = false;
});
</script>

<template>
  <section v-if="!loading">
    <div class="container" v-if="searchResults && searchResults.elements.length > 0 && MainImage">

      <div class="grid lg:grid-cols-1 gap-5">
        <div class="grid sm:grid-cols-2 gap-5">
          <div>
            <figure class="w-full h-full block">
              <NuxtImg  :src="MainImage" class="img-cover" alt="" width="284" format="webp" loading="lazy" />
            </figure>
          </div>
          <div class="flex flex-col gap-5">

            <div class="flex items-center" v-for="prod in searchResults.elements">
              <div class="w-1/3 pe-[10px]">
                <figure class="w-full h-full block">
                  <RouterLink
                      :to="buildUrlPrefix(getProductRoute(prod), getUrlPrefix())"
                      class="hover:text-pink"
                  >
                  <NuxtImg :src="prod.cover.media.url" class="img-cover" alt="" width="138" format="webp" loading="lazy" />
                  </RouterLink>
                </figure>
              </div>
              <div class="w-2/3">
                <h6 class="font-bold mb-1">
                  <RouterLink
                      :to="buildUrlPrefix(getProductRoute(prod), getUrlPrefix())"
                      class="hover:text-pink"
                  >
                    {{prod.translated.name}}
                  </RouterLink>
                </h6>
                <div class="mb-4 md:mb-2 lg:mb-4">
                  <SwListingProductPrice
                      :product="prod"
                      class="ml-auto flex"
                      data-testid="shop-the-look-product-price"
                  />
                </div>
                <div class="flex items-center gap-3">
                  <SharedWishlist :product="prod" />
                  <!--ProductAddToCart :product="prod" icon="fa-solid fa-bag-shopping"/-->
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </section>
</template>
